* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4 {
  font-family: "Fira Sans", sans-serif;
  margin: 0;
}

h2 {
  font-size: 25px;
  font-weight: bold;
}

h3 {
  font-size: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button.cta {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: white;
  background-color: #ee6b47;
  border: none;
  border-radius: 25px;
  padding: 15px 55px;
  cursor: pointer;
  transition: transform 0.1s, opacity 0.1s;
}

button.cta:active {
  transform: scale(0.98);
  opacity: 0.9;
}

button.wrapper {
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  border: none;
  cursor: pointer;
}

.toast {
  font-family: "Nunito Sans", sans-serif;
}
